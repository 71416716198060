import React from 'react';
import { OmFormInputFieldWrapper } from '@om/component-library-react';

const OmInputFieldWrapper = ({
    id,
    prefixString,
    validatorType,
    validationDelay,
    persistHandler,
    onShowingValidation,
    mask,
    reduxValue,
    isSensitive,
    placeholder,
    placeholderAsLabel = true,
    required = true,
    size = 'full',
    formId,
    name,
    keyboardMode = 'text',
    slot,
    label,
    labelAlwaysShow,
    hintText,
    disabled,
    align = 'left',
    maskSeparator,
    maxLength,
    useDisplayValue,
}) => {
    const determineValue = () => {
        if (useDisplayValue) {
            return reduxValue && reduxValue.displayValue;
        }
        return reduxValue && reduxValue.value;
    };

    return (
        <OmFormInputFieldWrapper
            onPersist={(e) => {
                if (persistHandler) {
                    persistHandler(e.detail);
                }
            }}
            onShowingValidation={(e) => {
                if (onShowingValidation) {
                    onShowingValidation(e.detail);
                }
            }}
            slot={slot}
            id={id}
            prefixString={prefixString}
            validatorType={validatorType}
            validationDelay={validationDelay}
            mask={mask}
            labelAlwaysShow={labelAlwaysShow}
            isSensitive={isSensitive}
            placeholder={placeholder}
            placeholderAsLabel={placeholderAsLabel}
            required={required}
            size={size}
            formId={formId}
            name={name}
            label={label}
            hintText={hintText}
            align={align}
            maskSeparator={maskSeparator}
            keyboardMode={keyboardMode}
            value={determineValue()}
            state={disabled ? 'disabled' : reduxValue && reduxValue.status}
            errorMessage={reduxValue && reduxValue.error}
            maxlength={maxLength}
        />
    );
};

export default OmInputFieldWrapper;
