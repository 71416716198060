import React from 'react';
import { graphql } from 'gatsby';
import { OmThreeColumnIllustration } from '@om/component-library-react';

const ThreeColumnIllustration = ({
    threeColumnIllustrationContent,
    buttonType,
    slot
}) => {
    if (!threeColumnIllustrationContent) {
        return '';
    }

    const {
        uid,
        has_boundary,

        img_url_left,
        redirect_url_left,
        redirect_url_left_android,
        redirect_url_left_apple,
        icon_name_left,
        sub_title_left,
        paragraph_left,
        button_text_left,
        button_action_type_left,
        button_url_left,

        img_url_middle,
        redirect_url_middle,
        redirect_url_middle_android,
        redirect_url_middle_apple,
        icon_name_middle,
        sub_title_middle,
        paragraph_middle,
        button_text_middle,
        button_action_type_middle,
        button_url_middle,

        img_url_right,
        redirect_url_right,
        redirect_url_right_android,
        redirect_url_right_apple,
        icon_name_right,
        sub_title_right,
        paragraph_right,
        button_text_right,
        button_action_type_right,
        button_url_right,

        button_text,
        button_url,
        button_action_type,
        button_call_me_back_product,
        button_call_me_back_email,

        open_tab,

        section_heading,
        use_heading_as_h1,
        section_caption,

        side_drawer_content_left,
        side_drawer_content_middle,
        side_drawer_content_right,
    } = threeColumnIllustrationContent;


    return (
        <section slot={slot}>
            <OmThreeColumnIllustration
                id={uid}
                hasBoundary={has_boundary}
                buttonType={buttonType}

                imageUrlLeft={img_url_left ? img_url_left.url : ''}
                iconNameLeft={icon_name_left}
                redirectUrlLeft={redirect_url_left}
                redirectUrlLeftAndroid={redirect_url_left_android}
                redirectUrlLeftApple={redirect_url_left_apple}
                subTitleLeft={sub_title_left}
                paragraphLeft={paragraph_left}
                buttonTextLeft={button_text_left}
                buttonActionTypeLeft={button_action_type_left}
                buttonUrlLeft={button_url_left}

                imageUrlMiddle={img_url_middle ? img_url_middle.url : ''}
                iconNameMiddle={icon_name_middle}
                redirectUrlMiddle={redirect_url_middle}
                redirectUrlMiddleAndroid={redirect_url_middle_android}
                redirectUrlMiddleApple={redirect_url_middle_apple}
                subTitleMiddle={sub_title_middle}
                paragraphMiddle={paragraph_middle}
                buttonTextMiddle={button_text_middle}
                buttonActionTypeMiddle={button_action_type_middle}
                buttonUrlMiddle={button_url_middle}

                imageUrlRight={img_url_right ? img_url_right.url : ''}
                iconNameRight={icon_name_right}
                redirectUrlRight={redirect_url_right}
                redirectUrlRightAndroid={redirect_url_right_android}
                redirectUrlRightApple={redirect_url_right_apple}
                subTitleRight={sub_title_right}
                paragraphRight={paragraph_right}
                buttonTextRight={button_text_right}
                buttonActionTypeRight={button_action_type_right}
                buttonUrlRight={button_url_right}

                buttonTexts={button_text}
                buttonUrl={button_url ? button_url.href : ''}
                buttonActionType={button_action_type}
                buttonCallMeBackProduct={button_call_me_back_product}
                buttonCallMeBackEmail={button_call_me_back_email}
                openTab={open_tab}
            >
                {
                    section_heading ?
                        (use_heading_as_h1 ?
                            <h1 slot="section-heading" dangerouslySetInnerHTML={{ __html: section_heading }} /> :
                            <span slot="section-heading" dangerouslySetInnerHTML={{ __html: section_heading }} />) :
                        ''
                }
                {
                    section_caption ?
                        <span slot="section-caption" dangerouslySetInnerHTML={{ __html: section_caption }} /> :
                        ''
                }

                {
                    side_drawer_content_left ?
                        <span slot="side-drawer-content-left" dangerouslySetInnerHTML={{ __html: side_drawer_content_left }} /> :
                        ''
                }
                {
                    side_drawer_content_middle ?
                        <span slot="side-drawer-content-middle" dangerouslySetInnerHTML={{ __html: side_drawer_content_middle }} /> :
                        ''
                }
                {
                    side_drawer_content_right ?
                        <span slot="side-drawer-content-right" dangerouslySetInnerHTML={{ __html: side_drawer_content_right }} /> :
                        ''
                }
            </OmThreeColumnIllustration>
        </section>
    );
};

export const pageQuery = graphql`
fragment ThreeColumnIllustration on Contentstack_3_col_illustration {
    id
    uid
    has_boundary
    use_heading_as_h1
    section_heading
    section_caption

    img_url_left {
    	...File
    }
    redirect_url_left
    redirect_url_left_android
    redirect_url_left_apple
    icon_name_left
    sub_title_left
    paragraph_left
    side_drawer_content_left
    button_text_left
    button_action_type_left
    button_url_left

    img_url_middle {
    	...File
    }
    redirect_url_middle
    redirect_url_middle_android
    redirect_url_middle_apple
    icon_name_middle
    sub_title_middle
    paragraph_middle
    side_drawer_content_middle
    button_text_middle
    button_action_type_middle
    button_url_middle

    img_url_right {
    	...File
    }
    redirect_url_right
    redirect_url_right_android
    redirect_url_right_apple
    icon_name_right
    sub_title_right
    paragraph_right
    side_drawer_content_right
    button_text_right
    button_action_type_right
	button_url_right
    button_text
    open_tab
    button_url {
    	...Link
    }
    button_action_type
        call_me_back {
        ...CallMeBack
    }
}
`;

export default ThreeColumnIllustration;
