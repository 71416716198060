import React from 'react';
import { OmFormDropdownFieldWrapper } from '@om/component-library-react';

const OmDropdownField = ({
    id,
    onSelectHandler,
    reduxValue,
    useNativeMobileDropdown = false,
    className,
    required,
    size = 'full',
    formId,
    placeholder,
    placeholderAsLabel = true,
    name,
    align,
    slot,
    label,
    displayLabel = true,
    disabled,
    isSensitive,
    filterable,
    helperText,
    options = [],
}) => {
    return (
        <OmFormDropdownFieldWrapper
            onOnSelect={(event) => {
                if (onSelectHandler) {
                    onSelectHandler(event);
                }
            }}
            id={id}
            slot={slot}
            useNativeMobileDropdown={useNativeMobileDropdown}
            className={className}
            required={required}
            size={size}
            formId={formId}
            placeholder={placeholder}
            name={name}
            align={align}
            filterable={filterable}
            label={label}
            placeholderAsLabel={placeholderAsLabel}
            disabled={disabled}
            helperText={helperText}
            displayLabel={displayLabel}
            isSensitive={isSensitive}
            value={reduxValue && reduxValue.value}
            state={reduxValue && reduxValue.status}
            errorMessage={reduxValue && reduxValue.error}
            options={JSON.stringify(options)}
        />
    );
};

export default OmDropdownField;
