import WebComponentsConfig from '../components/WebComponentsConfig';
import React, { useEffect, createRef } from 'react';
import {graphql} from 'gatsby';
import IframePageModularBlock from '../components/IframePageModularBlock';
import { useContentStackChromeExtension } from '../hooks/useContentStackChromeExtension';
import { getQueryParam } from '../util';
import { OmIframePage } from '@om/component-library-react';

const sendSizePostMessage = (height) => {
    if (window.parent) {
        window.parent.postMessage({
            frameHeight: height,
        }, '*');
    }
}

const IframePage = ({data, location}) => {
    const {
        theme,
        uid,
        locale,
        iframe_key,
        modular_blocks
    } = data.contentstackIframePage;

    useContentStackChromeExtension('iframe_page', uid, locale);

    // If the iframe key is not set correctly redirect home
    useEffect(() => {
        const iframeKeyParam = getQueryParam(location, 'iframe_key');
        if (iframe_key !== iframeKeyParam) {
            document.location.href= '/';
        }
    }, [location, iframe_key]);

    // Create resize observer which posts the iframe content height to the iframer
    const resizeElement = createRef();
    useEffect(() => {
        if (window.ResizeObserver) {
            const resizeObserver = new ResizeObserver((entries) => {
                if (entries && entries.length > 0) {
                    sendSizePostMessage(entries[0].contentRect.height);
                }
            });

            resizeObserver.observe(resizeElement.current);

            return () => {
                if (resizeObserver) {
                    const resizeObserverCopy = resizeObserver;
                    resizeObserverCopy.disconnect();
                }
            };
        }
    }, [resizeElement]);

    // Open links in new tab which arent 'anchors' on current page
    useEffect(() => {
        const observer = new MutationObserver(() => {
            // Get achors which dont open in new tab and dont start with #
            const anchorsForNewTab = document.querySelectorAll('a[href]:not([target$=blank], [href^="#"])');
            for (const anchor of anchorsForNewTab) {
                // Set open new tag
                anchor.setAttribute('target', '_blank');
            }
        });

        // Start observing the target node for configured mutations
        observer.observe(
            document.querySelector('[slot=page-content]'),
            {
                attributes: false,  // Dont observe attributes - we are changing them
                childList: true,
                subtree: true,
            },
        );
    }, []);

    return (
        <WebComponentsConfig themeContent={theme}>
            <OmIframePage>
                <div slot="page-content" ref={resizeElement}>
                    {modular_blocks ? modular_blocks.map((value) => {
                        return <IframePageModularBlock key={value.id} modularBlock={value} />;
                    }) : ''}
                </div>
            </OmIframePage>
        </WebComponentsConfig>
    );
};

export const pageQuery = graphql`
query IframePageQuery($id: String!) {
    contentstackIframePage(id: { eq: $id }) {
        id
        uid
        locale
        theme {
            ...Theme
        }
        iframe_key
        modular_blocks {
            ...IframePageModularBlock
        }
    }
}
`;

export default IframePage;
