import { useEffect } from 'react';

export function useContentStackChromeExtension(contentType, entryUid, locale) {
    useEffect(() => {
        if (contentType && entryUid) {
            document.body.setAttribute('data-contenttype', contentType);
            document.body.setAttribute('data-pageref', entryUid);
            document.body.setAttribute('data-locale', locale);
        } else {
            document.body.removeAttribute('data-contenttype');
            document.body.removeAttribute('data-pageref');
            document.body.removeAttribute('data-locale');
        }
    }, [contentType, entryUid, locale]);
}
