import React from 'react';
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby';
import { OmButton as OmButtonWrapper } from '@om/component-library-react';

import {
    setSideBarOpen as setSideBarOpenCmb,
    setCmbDetailsObj,
    setLeadSystem,
    setEnvironmentName
} from '@om/redux/actions/publicWeb/callMeBack';

import {
    setSideBarOpen as setSideBarOpenRoaQuoteTool,
    setSelectedQuoteTool,
} from '@om/redux/actions/roaQuoteTools/roaQuoteTool';

import { setToPremierSavingsQuoteInitial } from '@om/redux/actions/roaQuoteTools/premierSavingsQuoteTool';
import { setToElimikaQuoteInitial } from '@om/redux/actions/roaQuoteTools/elimikaQuoteTool';
import { setToAfyaImaraSeniorQuoteInitial } from '@om/redux/actions/roaQuoteTools/afyaImaraSeniorQuoteTool';
import { setToAccidentalDeathCoverQuoteInitial } from '@om/redux/actions/roaQuoteTools/lifeAndFuneralQuoteTool/accidentalDeathCoverQuoteTool/accidentalDeathCoverQuoteTool';
import { setToAfyaImaraQuoteInitial } from '@om/redux/actions/roaQuoteTools/afyaImaraQuoteTool';
import { setToWholeLifeCoverQuoteInitial } from '@om/redux/actions/roaQuoteTools/lifeAndFuneralQuoteTool/wholeLifeCoverQuoteTool/wholeLifeCoverQuoteTool';
import { setToPhysicalImpairmentQuoteInitial } from '@om/redux/actions/roaQuoteTools/physicalImpairmentCoverQuoteTool';
import { setToAccidentalDisabilityQuoteInitial  } from '@om/redux/actions/roaQuoteTools/accidentalDisabilityQuoteTool';
import { setToDeathTermCoverQuoteInitial } from '@om/redux/actions/roaQuoteTools/lifeAndFuneralQuoteTool/deathTermCoverQuoteTool/deathTermCoverQuoteTool';
import { setReceiverEmailAddress, toggleArbitrationForm, toggleComplaintForm } from '@om/redux/actions/customerComplaint/complaintArbitration';
import { toggleQualifyingSideDrawerFromWeb } from '@om/redux/actions/publicWeb/qualifyingCheckCallMeBack';
import { toggleSideDrawer } from '@om/redux/actions/publicWeb/asisaUnclaimedBenefits';
import { setMvestQuoteToInitial } from '@om/redux/actions/roaQuoteTools/mvestQuoteTool';
import { setDigiLifeQuoteInitial } from '@om/redux/actions/roaQuoteTools/digiLifeQuoteTool';

const keLeadEnvMap = {
    local: 'dev',
    dev: 'dev',
    qa: 'qa',
    preprod: 'prod',
    prod: 'prod',
};
const currentEnv = process.env.GATSBY_ENVIRONMENT_NAME;
let leadEnv = keLeadEnvMap[currentEnv];

// Default env
if (!leadEnv) {
    leadEnv = 'dev';
}

const openRoaQuoteTool = (dispatch, action) => {
    const selectedQuoteTool = action.quote_tool_name;
    dispatch(setSelectedQuoteTool(selectedQuoteTool));

    if (selectedQuoteTool === 'Premier Savings Plan') {
        dispatch(setToPremierSavingsQuoteInitial());
    } else if (selectedQuoteTool === 'Elimika Plan') {
        dispatch(setToElimikaQuoteInitial());
    } else if (selectedQuoteTool === 'Afya Imara Senior') {
        dispatch(setToAfyaImaraSeniorQuoteInitial());
    } else if (selectedQuoteTool === 'Accidental Death Cover') {
        dispatch(setToAccidentalDeathCoverQuoteInitial());
    } else if (selectedQuoteTool === 'Physical Impairment Cover') {
        dispatch(setToPhysicalImpairmentQuoteInitial());
    } else if (selectedQuoteTool === 'Whole Life Cover') {
        dispatch(setToWholeLifeCoverQuoteInitial());
    } else if (selectedQuoteTool === 'Physical Impairment Cover') {
        dispatch(setToPhysicalImpairmentQuoteInitial());
    } else if (selectedQuoteTool === 'Accidental Disability Plan') {
        dispatch(setToAccidentalDisabilityQuoteInitial());
    } else if (selectedQuoteTool === 'Death Term Cover') {
        dispatch(setToDeathTermCoverQuoteInitial());
    } else if (selectedQuoteTool === 'Afya Imara') {
        dispatch(setToAfyaImaraQuoteInitial());
    } else if (selectedQuoteTool === 'Mvest') {
        dispatch(setMvestQuoteToInitial());
    } else if (selectedQuoteTool === 'Digi Life') {
        dispatch(setDigiLifeQuoteInitial());
    }

    dispatch(setSideBarOpenRoaQuoteTool(true));
}

const openCmb = (dispatch, cmbDetails, leadSystem) => {
    if(cmbDetails && cmbDetails[0]){
        dispatch(setCmbDetailsObj(cmbDetails[0]));
    } else {
        dispatch(setCmbDetailsObj(cmbDetails));
    }
    if (leadSystem) {
        dispatch(setLeadSystem(leadSystem));
    }
    if (leadSystem === 'ke') {
        dispatch(setEnvironmentName(leadEnv));
    }
    dispatch(setSideBarOpenCmb(true));
}


export const handleOmButtonClick = (e, dispatch, prepopulated = false, component = '') => {
    let action;

    if(prepopulated){
        switch(component) {
        case('HeaderWithBreadcrumbs'):
        case('RefinedPromoBanner'):
        case('SegmentLandingHeader'):
        case('RefinedInPageBanner'):
        case('TwoColumnImageLeft'):
        case('TwoColumnImageRight'):
            if(!e || !e[0])
                return;
            action = e[0];
            openCmb(dispatch, {
                call_me_back_product: action.call_me_back_product,
                privacy_policy_url: action.privacy_policy_url,
                call_me_back_extra_fields: action.call_me_back_extra_fields,
            }, 'za');
            break;
        case('HeaderWithBreadcrumbsV2'):
        case('RefinedInPageBannerV2'):
        case('RefinedPromoBannerV2'):
        case('TwoColumnImageLeftV2'):
        case('TwoColumnImageRightV2'):
        case('SegmentLandingHeaderV2'):
            if(!e || !e.action){
                return;
            }
            action = e.action[0];
            break;
        default:
            break;
        }
    }else {
        if(!e || !e.detail)
            return;

        action = e.detail;
    }

    switch(action.__typename) {
    case('Contentstack_complaint_arbitration'):
        dispatch(setReceiverEmailAddress(action.email));
        if (action.type === 'Complaint') {
            dispatch(toggleComplaintForm());
        }
        if (action.type === 'Arbitration') {
            dispatch(toggleArbitrationForm());
        }
        break;
    case('Contentstack_call_me_back_email'):
        openCmb(dispatch, {
            call_me_back_email: action.email,
            call_me_back_email_campaign_code: action.campaign_code,
            privacy_policy_url: action.privacy_policy_url,
            call_me_back_extra_fields: action.extra_fields,
        });
        break;
    case('Contentstack_call_me_back_za'):
        openCmb(dispatch, {
            call_me_back_product: action.product_v2[0] ? action.product_v2[0].call_me_back_product : action.product,
            privacy_policy_url: action.privacy_policy_url,
            call_me_back_extra_fields: action.extra_fields,
        }, 'za');
        break;
    case('Contentstack_call_me_back_ke'):
        openCmb(dispatch, {
            call_me_back_product: action.product,
            privacy_policy_url: action.privacy_policy_url,
            call_me_back_extra_fields: action.extra_fields,
        }, 'ke');
        break;
    case('Contentstack_call_me_back_ng'):
        openCmb(dispatch, {
            call_me_back_product: action.product,
            privacy_policy_url: action.privacy_policy_url,
            call_me_back_extra_fields: action.extra_fields,
        }, 'ng');
        break;
    case('Contentstack_roa_quote_tool'):
        openRoaQuoteTool(dispatch, action);
        break;
    case('Contentstack_quick_apply'):
        dispatch(toggleQualifyingSideDrawerFromWeb(true));
        break;
    case('Contentstack_unclaimed_benefits'):
        dispatch(toggleSideDrawer());
        break;
    default:
        break;
    };
};

const OmButton = ({
    id,
    onClick,
    actionObject,
    type,
    iconName='arrow_forward',
    withIcon,
    size,
    text,
    disabled,
    actionType,
    slot,
    url,
    openTab = false,
    buttonImageUrl,
    callMeBackObject,
    roaQuoteToolObject,
    className,
}) => {

    const dispatch = useDispatch();

    if (actionObject) {
        return (
            <OmButtonWrapper
                id={id}
                className={className}
                slot={slot}
                onButtonAction={(e) => {
                    handleOmButtonClick(e, dispatch);
                }}
                actionObject={JSON.stringify(actionObject)}
                type={type}
                size={size}
                text={actionObject.display_text ? actionObject.display_text : text}
                iconName={iconName}
                withIcon={withIcon}
                disabled={disabled}
                buttonImageUrl={buttonImageUrl}
                onClick={(event) => {
                    if (onClick) {
                        onClick(event.detail);
                    }
                }}
            />
        );
    }
    return (
        <OmButtonWrapper
            id={id}
            className={className}
            slot={slot}
            type={type}
            size={size}
            text={text}
            url={url}
            openTab={openTab}
            iconName={iconName}
            withIcon={withIcon}
            actionType={actionType}
            disabled={disabled}
            buttonImageUrl={buttonImageUrl}
            callMeBackObject={callMeBackObject}
            roaQuoteToolObject={roaQuoteToolObject}
            onClick={(event) => {
                if (onClick) {
                    onClick(event.detail);
                }
            }}
        />
    );
}

export const pageQuery = graphql`
fragment RefinedInPageBannerButton on Contentstack_refined_in_page_banner_v2_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
    }
}

fragment RefinedPromoBannerButton on Contentstack_refined_promo_banner_v2_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment HeaderWithBreadcrumbsPrimaryButton on Contentstack_header_with_breadcrumbs_v2_hero_banner_primary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment HeaderWithBreadcrumbsSecondaryButton on Contentstack_header_with_breadcrumbs_v2_hero_banner_secondary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment SegmentLandingHeaderPrimaryButton on Contentstack_segment_landing_header_v2_hero_banner_primary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment SegmentLandingHeaderSecondaryButton on Contentstack_segment_landing_header_v2_hero_banner_secondary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment TwoColumnImageLeftPrimaryButton on Contentstack_2_col_image_left_v2_primary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment TwoColumnImageLeftSecondaryButton on Contentstack_2_col_image_left_v2_secondary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment TwoColumnImageRightPrimaryButton on Contentstack_2_col_image_right_v2_primary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment TwoColumnImageRightSecondaryButton on Contentstack_2_col_image_right_v2_secondary_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...CallMeBackNg
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment TriggerQuestionButton on Contentstack_trigger_question_button {
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment QuizPrimaryButton on Contentstack_quiz_primary_button{
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment UpNextCardPrimaryButton on Contentstack_up_next_card_primary_button{
    display_text
    action {
        __typename
        ...UrlButtonAction
        ...ComplaintArbitration
        ...CallMeBackEmail
        ...CallMeBackZa
        ...CallMeBackKe
        ...RoaQuoteTool
        ...QualifyingCheckCallMeBack
        ...AsisaUnclaimedBenefits
    }
}

fragment UrlButtonAction on Contentstack_url_button_action {
    uid
    url
    open_tab
}
`;

export default OmButton;