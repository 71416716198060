import {graphql} from 'gatsby';
import React from 'react';
import ApplicationContainer from './ApplicationContainer';
import OneColumn from './OneColumn';
import TwoColumn from './TwoColumn';
import Tables from './Tables';
import ThreeColumnIllustration from './ThreeColumnIllustration';
import Carousel from './Carousel';
import FaqCards from './FaqCards';
import Banner from './Banner';

const PageModularBlock = ({
    modularBlock,
}) => {
    if (!modularBlock) {
        return '';
    }
    const {
        application_container_block,
        one_column_block,
        two_column_block,
        three_column_illustration_block,
        table_block,
        banner_block,
        carousel_block,
        faq_cards_block,
    } = modularBlock;

    if (application_container_block != null) {
        return (
            <ApplicationContainer applicationContainerContent={application_container_block.application_container[0]}/>
        );
    }

    if (one_column_block != null) {
        return <OneColumn oneColumnContent={one_column_block.one_column[0]} />
    }

    if (two_column_block != null) {
        return (
            <TwoColumn twoColumnContent={two_column_block.two_column[0]}/>
        );
    }

    if (three_column_illustration_block != null) {
        return (
            <ThreeColumnIllustration
                threeColumnIllustrationContent={three_column_illustration_block.three_column_illustration[0]}/>
        );
    }

    if (table_block != null) {
        return (
            <Tables tableContent={table_block.table[0]}/>
        );
    }

    if (banner_block != null) {
        return (<Banner bannerContent={banner_block.banner[0]}/>);
    }

    if (carousel_block != null) {
        return (
            <Carousel carouselContent={carousel_block.carousel[0]}/>
        );
    }

    if (faq_cards_block != null) {
        return (
            <FaqCards faqCardsContent={faq_cards_block.faq_cards[0]}/>
        );
    }

    return '';
};

export const pageQuery = graphql`
fragment IframePageModularBlock on Contentstack_iframe_page_modular_blocks {
    id
    application_container_block {
        application_container {
            __typename
            ...ApplicationContainer
        }
    }

    one_column_block {
        one_column {
            __typename
            ...TabsOneColumnTextParagraph,
            ...PageIntro,
            ...Paragraph
        }
    }

    two_column_block {
        two_column {
            __typename
            ...TwoColumnImageLeft,
            ...TwoColumnImageLeftV2,
            ...TwoColumnImageRight,
            ...TwoColumnImageRightV2,
            ...TabsTwoColumnLayoutImageRight,
            ...TabsTwoColumnLayoutImageRightV2,
            ...TabsTwoColumnLayoutImageLeft,
            ...TabsTwoColumnLayoutImageLeftV2
        }
    }

    three_column_illustration_block {
        three_column_illustration {
            __typename
            ...ThreeColumnIllustration
        }
    }

    table_block {
        table {
            __typename
            ...Table,
            ...TabsTable
        }
    }

    banner_block {
        banner {
            __typename
            ...RefinedInPageBanner,
            ...RefinedInPageBannerV2,
            ...RefinedPromoBanner,
        }
    }

    carousel_block {
        carousel {
            __typename
            ...ProductCarousel,
            ...DownloadCarousel,
            ...ArticleCarousel,
            ...TabsProductCarousel,
            ...InPageCarouselWrapper
        }
    }

    faq_cards_block {
        faq_cards {
            ...FaqCards
        }
    }
}
`;

export default PageModularBlock;
